@import './settings';
.col-spacing-md{
  >:not(:last-child){
    @media (max-width: $tabletBreakdown) {
      margin-bottom: 16px;
    }
  }
}
.col-spacing-sm{
  >:not(:last-child){
    @media (max-width: $mobileBreakdown) {
      margin-bottom: 16px;
    }
  }
}
.row-spacing{
  >:not(:last-child){
    margin-bottom: 20px;
    @media (max-width: $mobileBreakdown) {
      margin-bottom: 16px;
    }
  }
}
.item-spacing-right{
  >:not(:last-child){
    margin-right: 16px;
  }
}
