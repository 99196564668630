@import './settings.scss';

$theme-colors: (
  'primary': $bgDark,
  'secondary': $fontLight,
  'danger': $red,
);

@import '~bootstrap/scss/bootstrap';
@import '~@tkrotoff/bootstrap-floating-label/src/bootstrap-floating-label';

.btn {
  border-radius: 12px;
  font-family: $titleFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  padding: 0.45rem 1.8125rem;
}

.btn-lg {
  padding: 1rem 3.313rem;
  font-size: 1rem;
  @media screen and (max-width: $mobileBreakdown) {
    padding: 0.625rem 1.875rem;
  }
}

.btn-outline-secondary {
  border-width: 1px;
}

.progress {
  background: linear-gradient(180deg, $bg, 50%, $lightGray 60%);
  height: 3px;
  border-radius: 1rem;
}

.carousel-item {
  transition: transform 1s ease-in-out;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0;
  @media screen and (max-width: $mobileBreakdown) {
    width: 100%;
    // margin: 0;
    padding-right: 0;
    margin-top: 5vh;
  }
  @media (min-width: 576px) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}
.modal-content {
  width: 100%;

  max-width: 54.563rem;
  border: none;
  border-radius: 24px;

  @media screen and (max-width: $mobileBreakdown) {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  background-color: darken($bg, 1%);
}

.modal-header {
  display: flex;
  border: none;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
  padding-left: 8.188rem;
  padding-right: 8.188rem;
  padding-top: 4.813rem;
  @media screen and (max-width: $mobileBreakdown) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.modal-footer {
  justify-content: center;
  border: none;
  // padding-bottom: 3.875rem;
  padding-bottom: 4.9rem;
  padding-left: 8.188rem;
  padding-right: 8.188rem;

  @media screen and (max-width: $mobileBreakdown) {
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.75rem;
    padding-top: 0;
  }
  button {
    width: 18.063rem;
    @media screen and (max-width: $mobileBreakdown) {
      width: 100%;
    }
  }
}

.modal-body {
  padding-left: 8.188rem;
  padding-right: 8.188rem;
  @media screen and (max-width: $mobileBreakdown) {
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0;
  }
}

.form-control {
  border-color: $lightGray;
  border-radius: 0.375rem;
  color: $gray3;
  font-size: 0.938rem;
  line-height: 1.25rem;
}

// .input.form-control-lg {
//   font-size: 0.936rem;
// }
.floating-label {
  font-size: 0.936rem;
}

.DontaionPopup__form {
  width: 100%;
  height: 100%;
}

.floating-label > input:focus + label,
.floating-label > input:focus + label.label-sm,
.floating-label > textarea:focus + label,
.floating-label > textarea:focus + label.label-sm {
  color: $gray3;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.floating-label > input[value]:not([value='']) + label,
.floating-label > input[value]:not([value='']) + label.label-sm,
.floating-label > textarea:not(:empty) + label,
.floating-label > textarea:not(:empty) + label.label-sm {
  color: $gray3;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.form-control-lg {
  height: 3rem;
}

.clickableIcon {
  user-select: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.clickableIcon:active {
  background-color: darken($bg, 5%);
}

.buttonSpinner {
  margin-left: 1rem;
}

.serverError {
  font-weight: 400;
  font-size: 1.2rem;
  color: darken($red, 15%);
  line-height: 1.6rem;
  margin-bottom: 1rem;
}

.topBtn {
  font-family: $textFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  background-color: $bgDark;

  &-outline {
    @extend .topBtn;
    background-color: $bg;
    color: $bgDark;

    &--disabled {
      @extend .topBtn-outline;
      cursor: not-allowed;

      color: lighten($bgDark, 8%);
      background-color: darken($bg, 3%);
    }
  }
}

.topBtn-outline--disabled:focus {
  @extend .topBtn-outline--disabled;
  box-shadow: none;
}
.topBtn-outline--disabled:active {
  @extend .topBtn-outline--disabled;
  box-shadow: none;
}
.topBtn-outline--disabled:hover {
  @extend .topBtn-outline--disabled;
}

.dropdown-menu {
  border: 1px solid $border;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 100%;
}
.checkbox{
  .form-check-input{
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #E0E0E0;
    transition: all .2s;
    margin-top: 0;
    &:hover {
      box-shadow:0 0 3px 0px #E0E0E1 inset;
    }
    &:checked{
      background-color: #333333;
      box-shadow: none;
    }
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 8px;
    }
    &:checked::before{
      background-image: url("../../assets/icons/check.svg");
    }
  }
  .form-check-label{
    margin-left: 15px;
    font-family: $textFontFamily;
  }
}
