$titleFontFamily: 'Playfair Display', serif;
$textFontFamily: Helvetica;
$nunitoSans: 'Nunito Sans', sans-serif;

$fontSmaller: 16px;
$fontMiddle: 16px;
$fontLarge: 18px;

$fontSize: $fontMiddle; // default

// Breakpoints
$mobileBreakdown: 720px;
$tabletBreakdown: 1023px;

// Font breakpoints
$fontSmallBreakpoint: 1200px; //$mobileBreakdown;
$fontLargeBreakpoint: 1700px;

$bg: #ffffff;
$bgDark: #252525;
$fontBlack: #151515;
$fontLight: #ffffff;
$red: #f13733;
$lightRed: #eb5757;
$gray: #afafaf;
$yellow: #fdcd06;
$lightGreen: #8fba6d;
$black: #000000;
$border: #f2f2f2;
$gray3: #828282;
$darkGray: #333333;
$lightGray: #e0e0e0;
$gray2: #9a9a9a;
$anotherGray: #909090;
$gray1: #f8f8f8;
$gray4: #858585;
$border2: #f0f0f0;
$priceColor: #d2dae2;
$disabled: #e9ecef;
$gray6: #F2F2F2;

:export {
  bg: $bg;
  bgDark: $bgDark;
  fontBlack: $fontBlack;
  titleFontFamily: $titleFontFamily;
  textFontFamily: $textFontFamily;
  fontLight: $fontLight;
  red: $red;
  fontBlack: $fontBlack;
  gray: $gray;
  yellow: $yellow;
  mobileBreakdown: $mobileBreakdown;
  lightGreen: $lightGreen;
  fontSize: $fontSize;
  black: $black;
  border: $border;
  gray3: $gray3;
  lightGray: $lightGray;
  gray2: $gray2;
  anotherGray: $anotherGray;
  gray1: $gray1;
  gray4: $gray4;
  border2: $border2;
  priceColor: $priceColor;
  disabled: $disabled;
}
