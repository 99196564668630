@import './settings.scss';

.main-table {
  color: $gray4;
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 1em;
  &-wrap {
    min-height: 150px;
    height: 100%;
  }
  @media (max-width: $tabletBreakdown) {
    width: 725px;
  }
  @media (max-width: $mobileBreakdown) {
    width: 700px;
  }

  thead {
    th {
      padding: 0.85rem 1.3rem;
      border: none;
      font-weight: 700;
    }
  }

  tbody {
    tr {
      margin-bottom: 10px;
      border-radius: 5px;
      td {
        // overflow: hidden;
        border: 1px solid $border2;
        border-width: 1px 0;
        vertical-align: middle;
        padding: 0.85rem 1.3rem;
        &:first-of-type {
          border: 1px solid $border2;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-right: none;
        }
        &:last-of-type {
          border: 1px solid $border2;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-left: none;
        }
      }
    }
  }
  &__sort {
    padding: 0;
    font-family: $textFontFamily;
    font-weight: 700;
    color: $gray4;
    font-size: 12px;
    &:after {
      margin-left: 9px;
      display: inline-block;
      vertical-align: inherit;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #c4c4c4;
    }
    &:focus,
    .focus,
    &:hover {
      box-shadow: none;
      text-decoration: none;
      color: $gray4;
    }
    &.active {
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 9px solid #c4c4c4;
        border-top-width: 0;
      }
    }
  }
  &__dropdown {
    .dropdown-menu {
      height: 100px;
      overflow-y: auto;
    }
    .nav-link {
      padding: 0;
      color: $gray4;
      display: flex;
      align-items: center;
      &:after {
        margin-left: 1.055em;
        vertical-align: 0.255em;
        border-top: 9px solid #c4c4c4;
        border-right: 5px solid transparent;
        border-bottom: 0;
        border-left: 5px solid transparent;
      }
    }
    .dropdown-item {
      font-size: 12px;
      color: $gray4;
      padding: 0.25rem 0.5rem;
    }
  }
  &__action {
    position: relative;
    .btn {
      padding: 0.25rem 0.9125rem;
      &:focus,
      &.focus {
        box-shadow: none;
        img {
          filter: brightness(-100%);
        }
      }
    }
  }
  &__btn {
    text-align: center;
    .btn {
      min-width: 91px;
      padding: 0.25rem 0.9125rem;
      color: $fontLight;
      font-family: $textFontFamily;
      font-size: 12px;
      border-radius: 5px;
    }
    &.completed {
      .btn {
        background-color: #6fcf97;
        border-color: #6fcf97;
        &:active,
        &.active {
          background-color: darken(#6fcf97, 10%);
          border-color: darken(#6fcf97, 10%);
        }
      }
    }
    &.created {
      .btn {
        background-color: #f2c94c;
        border-color: #f2c94c;
        &:active,
        &.active {
          background-color: darken(#f2c94c, 10%);
          border-color: darken(#f2c94c, 10%);
        }
      }
    }
    &.canceled {
      .btn {
        background-color: #eb5757;
        border-color: #eb5757;
        &:active,
        &.active {
          background-color: darken(#eb5757, 10%);
          border-color: darken(#eb5757, 10%);
        }
      }
    }
    &.notStarted {
      .btn {
        background-color: $lightGray;
        border-color: $lightGray;
        &:active,
        &.active {
          background-color: darken($lightGray, 10%);
          border-color: darken($lightGray, 10%);
        }
      }
    }
  }
  &__avatar {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      max-height: 100%;
    }
  }
}
